export class GeneralUtil {
  static formatNumber(value: string): string {
    const [integerPart, decimalPart] = value.split(',');
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    const formattedValue = decimalPart
      ? `${formattedInteger},${decimalPart}`
      : formattedInteger;

    return formattedValue;
  }

  static traverse(obj: any) {
    try {
      if (obj === null || obj === undefined) {
        return obj;
      }
      if (typeof obj === 'object') {
        if (Array.isArray(obj)) {
          obj.forEach((item: any, index: number) => {
            obj[index] = this.traverse(item);
          });
        } else {
          Object.keys(obj).forEach((key) => {
            obj[key] = this.traverse(obj[key]);
          });
        }
      } else if (typeof obj === 'string') {
        if (/^\d{4,},\d{2}$/.test(obj)) {
          return this.formatNumber(obj);
        }
      }
    } catch (error) {
      return obj;
    }

    return obj;
  }

  static isEmpty(filterValue: any): boolean {
    if (typeof filterValue === 'string') {
      return filterValue.trim().length === 0;
    } else if (Array.isArray(filterValue)) {
      return filterValue.length === 0;
    } else if (filterValue && typeof filterValue === 'object') {
      return Object.keys(filterValue).length === 0;
    }
    return !filterValue;
  }

  static copyPropertiesOfObject(obj, properties) {
    const target = {};
    properties.forEach(prop => {
      if (obj.hasOwnProperty(prop)) {
        target[prop] = obj[prop];
      }
    });
    return target;
  }

  static extractFields(obj:any, fields:any) {
    const newObj = {};
    fields.forEach(field => {
      if (field in obj) {
        newObj[field] = obj[field];
      } else if (obj?.healthProfile && field in obj.healthProfile) {
        newObj[field] = obj.healthProfile[field];
      }
    });
    return newObj;
  }

  static isObjectValid(obj: any) {
    for (let key in obj) {
      if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
        return false;
      }
    }
    return true;
  }

  static findEmptyFields(obj: any) {
    const emptyFields = [];
    function checkFields(object, parentKey = "") {
      for (let key in object) {
        const fullKey = parentKey ? `${parentKey}.${key}` : key;

        if (object[key] === null || object[key] === undefined || object[key] === "") {
          emptyFields.push(fullKey);
        }
        else if (typeof object[key] === "object" && !Array.isArray(object[key])) {
          checkFields(object[key], fullKey);
        }
      }
    }
    checkFields(obj);
    return emptyFields;
  }

}
