import { JsonPipe, KeyValuePipe } from '@angular/common';
import {
  Component,
  DestroyRef,
  Host,
  inject,
  Input,
  Optional,
  signal,
  SkipSelf,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlContainer,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { tap } from 'rxjs';
import { DEFAULT_INPUT_TYPE } from '../../form-controls/form-controls.const';
import { ValidationMessageComponent } from '../validation-message/validation-message.component';

@Component({
  selector: 'app-nd-form-input-text',
  templateUrl: './form-input-text-nd.component.html',
  styleUrls: ['./form-input-text-nd.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    NgxMaskDirective,
    TranslateModule,
    ValidationMessageComponent,
  ],
  providers: [provideNgxMask()],
})
export class FormInputTextNdComponent {
  @Input({
    required: true,
    transform: (value: string) => {
      return 'text-input-' + value;
    },
  })
  testId: string;
  @Input() label: string = ''; // Label for the input field
  @Input() placeholder: string = ''; // Placeholder for the input
  @Input() hint: string;
  @Input() alignHint: 'start' | 'end' = 'start';

  @Input() type = DEFAULT_INPUT_TYPE; // Type of the input (text, number, email, etc.)
  @Input() transformFns: ((value: string) => string)[] = []; // Accept an array of transformation functions
  @Input() disabled: boolean = false;

  @Input() step = 1;
  @Input() mask: string = null;
  
  @Input() minNumberValue  = Number.MIN_SAFE_INTEGER;
  @Input() maxNumberValue  = Number.MAX_SAFE_INTEGER;
  @Input() maxLength  = 100;
  @Input() dropSpecialCharacters: boolean = false;

  @Input() suffix: string;
  @Input() prefix: string;

  @Input() controlName: string; // Name of the form control
  @Input() control: AbstractControl;

  required = signal<boolean>(false);

  readonly #destroyRef = inject(DestroyRef);

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
  ) {}

  ngOnInit() {
    if (!this.control && this.controlName && this.controlContainer) {
      this.control = (this.controlContainer?.control as FormGroup)?.get(
        this.controlName,
      );
    } else {
      this.control = new FormControl();
    }
 
    if (this.control) {
      
      this.addDefaultValidators();
      this.setDefaultValues();

      if (this.disabled) {
        this.control.disable();
      }

      this.control.valueChanges
        .pipe(
          // Ensure the logic only applies once after the value change event is triggered
          tap((value: string) => {
            let transformedValue = value;
            this.transformFns.forEach((fn) => {
              transformedValue = fn(transformedValue);
            });

            // Set the transformed value without triggering a new event
            if (value !== transformedValue) {
              this.control?.setValue(transformedValue, { emitEvent: false });
            }
          }),
          takeUntilDestroyed(this.#destroyRef),
        )
        .subscribe();
    }
  }

  addDefaultValidators() {
    if (this.type === 'number') {
      
      if (this.minNumberValue !== null && !this.control.hasValidator(Validators.min(this.minNumberValue))) {
        this.control.addValidators(Validators.min(this.minNumberValue));
      }

      if (this.maxNumberValue !== null && !this.control.hasValidator(Validators.max(this.maxNumberValue))) {
        this.control.addValidators(Validators.max(this.maxNumberValue));
      }
      
    } else if (this.type === 'text') {
      if (this.maxLength !== null && !this.control.hasValidator(Validators.maxLength(this.maxLength))) {
        this.control.addValidators(Validators.maxLength(this.maxLength));
      }
    }

    this.control.updateValueAndValidity();
  }

  setDefaultValues() {
    this.required.set(this.control.hasValidator(Validators.required));
  }

}
