
  <mat-form-field class="custom-datetime-input-form">
    <mat-label
      class="custom-datetime-label"
    >
      {{ label | translate }}</mat-label
    >
    <div class="custom-datetime-input" [attr.isDisabled]="disabled || null">
      <input
        matInput
        [formControl]="control"
        [matDatepicker]="datePicker"
        [min]="minDateValue"
        [max]="maxDateValue"
        maxlength="16"
        [required]="required()"
        (keydown)="datetimeregex($event)"
        #datePickerInput
        (dateChange)="onChangeDate()"
        (change)="valueChangeHandler($event)"
        (focusout)="valueChangeHandler($event)"
        [attr.testId]="testId"
      />

      <mat-datepicker-toggle
      class="custom-datetime-toggle-suffix"
      [for]="datePicker"
    ></mat-datepicker-toggle>

    </div>

   <mat-hint class="custom-datetime-input-hint">{{ hint ?? '' }}</mat-hint>

   <mat-error class="custom-datetime-input-error">
     @if(control?.errors){
     <app-validation-message [errors]="control.errors"></app-validation-message>
     }
   </mat-error>
  </mat-form-field>


<mat-datepicker
panelClass="custom-mat-datetime-panel-class"
#datePicker
[startView]="startView"
(monthSelected)="chosenMonthHandler($event, datePicker)"
(opened)="onDatepickerOpened()"
(closed)="onDatepickerClosed()"
></mat-datepicker>
