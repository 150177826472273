
  <mat-form-field class="custom-datepicker-input-form">
    <mat-label class="custom-datepicker-label"> {{ label | translate }}</mat-label>
    
    <div class="custom-datepicker-input" [attr.disabled]="disabled ? '' : null">
      <input
        matInput
        [formControl]="control"
        [matDatepicker]="datePicker"
        [min]="minDateValue"
        [max]="maxDateValue"
        maxlength="10"
        [required]="required()"
        (keydown)="dateRegex($event)"
        (dateChange)="onChangeDate()"
        (change)="valueChangeHandler($event)"
        [attr.testId]="testId"
      />

     <mat-datepicker-toggle
      class="custom-datepicker-toggle-suffix"
      [for]="datePicker"
    ></mat-datepicker-toggle>


    </div>

   <mat-hint class="custom-datepicker-input-hint">{{ hint ?? '' }}</mat-hint>

   <mat-error class="custom-datepicker-input-error">
     @if(control?.errors){
     <app-validation-message [errors]="control.errors"></app-validation-message>
     }
   </mat-error> 
  </mat-form-field>

  <mat-datepicker
  panelClass="custom-mat-datepicker-panel-class"
  #datePicker
  (opened)="onDatepickerOpened()"
  (closed)="onDatepickerClosed()"
  ></mat-datepicker>
