import {
  Component,
  Input,
  Optional,
  Host,
  SkipSelf,
  ViewEncapsulation,
  signal,
  OnInit,
} from '@angular/core';
import {
  ControlContainer,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import {
  DEFAULT_MIN_DATE_ND,
  DEFAULT_MAX_DATE_ND,
  DATE_REGEX,
  DEFAULT_START_DATE_PLACEHOLDER,
  DEFAULT_END_DATE_PLACEHOLDER,
  APP_DATE_FORMATS_ND,
} from '../../form-controls/form-controls.const';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ValidationMessageComponent } from '../validation-message/validation-message.component';
import { DateUtils } from '../../utilities';
@Component({
  selector: 'app-nd-form-input-date-range',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    TranslateModule,
    MatDatepickerModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    ValidationMessageComponent,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './form-input-date-range-nd.component.html',
  styleUrls: ['./form-input-date-range-nd.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS_ND }],
})
export class FormInputDateRangeNdComponent implements OnInit{
  @Input() label: string;
  @Input() disabled: boolean = false;
  @Input() startDatePlaceholder = DEFAULT_START_DATE_PLACEHOLDER;
  @Input() endDatePlaceholder = DEFAULT_END_DATE_PLACEHOLDER;
  @Input() minDate = DEFAULT_MIN_DATE_ND;
  @Input() maxDate = DEFAULT_MAX_DATE_ND;
  @Input() hint: string;
  @Input({
    required: true,
    transform: (value: string) => {
      return 'app-form-input-date-range' + value;
    },
  })
  testId: string;
  checkDateRegex = DATE_REGEX;
  startDate: Date;
  endDate: Date;
  required = signal<boolean>(false);

  constructor(
    @Optional() @Host() @SkipSelf() public controlContainer: ControlContainer,
  ) {}

  ngOnInit(): void {
    if (this.controlContainer.control) {
      this.setDefaultValues();
    } 
  }

  /**
   * Below dateChange methods handle the change event for the input of the start/end date manual.
   * Validates the start/end date and sets appropriate errors if necessary.
   * @param event$ - The event object from the start/end date input change.
   */
  onStartDateChange(event$: Event) {
    const startDateValue = (event$.target as HTMLInputElement)?.value;

    if (!startDateValue) return;

    this.startDate = DateUtils.parseStringToDate(startDateValue);

    if (!DateUtils.isValidDate(startDateValue)) {
      this.controlContainer?.control
        ?.get('startDate')
        ?.setErrors({ matDatepickerParse: true });
      return;
    }

    if (
      this.startDate &&
      (this.startDate > this.maxDate || this.startDate < this.minDate)
    ) {
      this.controlContainer.control
        .get('startDate')
        .setErrors({ matDatepickerParse: true });
      return;
    }

    if (this.controlContainer?.control?.get('startDate')?.errors) {
      this.controlContainer.control.get('startDate').setErrors(null);
    }

    if (this.startDate && this.endDate && this.startDate > this.endDate) {
      this.controlContainer?.control
        ?.get('startDate')
        ?.setErrors({ matDatepickerMin: true });
      return;
    } else {
      if (this.controlContainer?.control?.get('startDate')?.errors) {
        delete this.controlContainer.control.get('startDate').errors[
          'matDatepickerMin'
        ];
      }
      if (this.controlContainer?.control?.get('endDate')?.errors) {
        delete this.controlContainer.control.get('endDate').errors[
          'matDatepickerMin'
        ];
      }
    }
  }

  onDateRangeChange() {
    const startDateValue = this.controlContainer.control.get('startDate').value;
    this.startDate = DateUtils.convertMomentToDate(startDateValue);
    const endDateValue = this.controlContainer.control.get('endDate').value;
    this.endDate = DateUtils.convertMomentToDate(endDateValue);
  }

  onEndDateChange(event$: Event) {
    const endDateValue = (event$.target as HTMLInputElement)?.value;
    if (!endDateValue) return;

    this.endDate = DateUtils.parseStringToDate(endDateValue);
    if (!DateUtils.isValidDate(endDateValue)) {
      this.controlContainer?.control
        ?.get('endDate')
        ?.setErrors({ matDatepickerParse: true });
      return;
    }

    if (
      this.endDate &&
      (this.endDate > this.maxDate || this.endDate < this.minDate)
    ) {
      this.controlContainer?.control
        ?.get('endDate')
        ?.setErrors({ matDatepickerParse: true });
      return;
    }

    if (this.controlContainer?.control?.get('endDate')?.errors) {
      this.controlContainer.control.get('endDate').setErrors(null);
    }
    
    if (this.startDate && this.endDate && this.startDate > this.endDate) {
      this.controlContainer?.control
        ?.get('endDate')
        ?.setErrors({ matDatepickerMin: true });
      return;
    } else {
      if (this.controlContainer?.control?.get('startDate')?.errors) {
        delete this.controlContainer.control.get('startDate').errors[
          'matDatepickerMin'
        ];
      }
      if (this.controlContainer?.control?.get('endDate')?.errors) {
        delete this.controlContainer.control.get('endDate').errors[
          'matDatepickerMin'
        ];
      }
    }
  }

  setDefaultValues() {
    this.required.set(this.controlContainer.control.hasValidator(Validators.required));
  }

}

